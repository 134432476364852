.purple{background-color: #205370;}
.textPurple{color:  #3C3C74;} 

.white{background-color: #FFFFFF;}

.lightGrey{background-color: #F5F5F5 !important;}

.blue{background-color: #6495ED;}

.red{background-color:#CD5C5C;}

.seaGreen{background-color: #004251;}
.darkred{background-color: #6A3A4F;}
.lightPurple{background-color: #004251 !important;}

.babyPink{background-color: #E37A91;}

.blackText{color: black;}
 
.greyText{color: #ACACAC;}

.whiteText{color:white;}

.btmborderblue{border-bottom: 3px solid #6495ED!important;}

.btmborderred{border-bottom: 3px solid  #CD5C5C!important;}

.btmborderdarkred{border-bottom: 3px solid #6A3A4F!important;}

.btmborderpurple{border-bottom: 3px solid #5D62B4!important;}

.btmborderbabypink{border-bottom: 3px solid #E37A91!important;}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body{
  font-family: 'Roboto', sans-serif !important;

}

/* 
@font-face {
  font-family: TrajanPro;
  src: url(../src/Fonts/TrajanPro-Regular.woff);
}

@font-face {
  font-family: Sitka;
  src: url(../src/Fonts/Sitka.ttc);
}
@font-face {
  font-family: TrajanProBold;
  src: url(../src/Fonts/TrajanPro-Bold.woff);
} */
.w_auto{width:fit-content;}

.TrajanPro{font-family: 'Roboto', sans-serif !important;}

.Sitka{font-family: 'Roboto', sans-serif !important ;}

html ,body ,#root{
height: 100%;
}

.fullHeight{
  height :100%
}
.disabledddd{
  opacity: 0.5 !important;
}

.toggle{
  width:25px !important;
  float:right;
}
.logo{
  height:35px ;
}
.sideText{
  font-family: TrajanProBold;
  margin: 13px;
  font-size: 16px;
}

.blackIcon{fill:black !important;}

.whiteIcon{fill:white !important;}

.sideIcon{
  height: 25px;
  margin: 10px;
}
hr{
  margin-bottom: 0px!important;
  margin-top: 0px!important;
}
.sideIconAcc{
  height: 30px;
  margin: 10px;

}
.sideIconHr{
  height: 20px;
  margin: 10px;

}
.sideButton{
  border-radius: 12px;
  height:52px;
  width: 100%;
  background-color: inherit;
  border: none;
}
.sideButton:focus{
  outline:none;
}
.sideButton:hover{
  border-radius: 12px;
  height:52px;
  width: 100%;
  background-color:#004251;
}
.search{
  width: 68%;
  margin-left: 13px;
  border-radius: 40px;
  outline: none!important;
  height: 30px;
  border: none;
  margin-right: 20px;
}
.navbar-style{
  height: 3.3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.icon2-font{
  font-size: 20px;

}
.icon1-font{
  font-size: 25px;
  margin-left: 10px;
}
.icon3-font{
  font-size: 35px;
}
.line-height{
  margin-top: 5px!important;
}
.accountBtn{
  margin-top: 2px;
  background: none!important;
  border: none!important;
  outline: none!important;
}

.hide{
  display: none !important;
}

.show>.btn-success.dropdown-toggle:focus{
  box-shadow: none!important;
}
.btn-success:focus {
  box-shadow: none!important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  font-size: 33px;
  color:#3C3C74;
}
.chatboximage {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

#root{
  background-color: #F5F5F5;
}
.dropdown-toggle::after {
display: none!important;
}
.drop{
  font-size: xxx-large;
}
.dropdown-toggle{
  padding: 0px!important;
}

.activity-indicator {
	width: 100%;
	height: 100%;
	min-height: 50px;
	position: relative;
}
.activity-indicator * {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box
}

.activity-indicator > div {
	left: 50%;
	top: 50%;
	width: auto;
	display: inline-block;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.activity-indicator > div > div {
	-webkit-transition-duration: 0.4s;
	        transition-duration: 0.4s;
}
.td_date{
  width: 110px !important;
}
.pointer{
  cursor: pointer;
display: flex;
margin-top: 10px;
}
.width_modal{
  width: 60% !important;
}