.button1 {
  font-family: Sitka;
  margin-top: 10px;
  margin-left: 10px;
  border: none !important;
  color: white;
  text-align: center;
  font-size: 1vw;
  height: 39px;
  border-radius: 15px;
  outline: none !important;
  width: 15%;
}

.tabtopPrint {
  /* display:"flex",gap:"10px",justifyContent:"space-between",margin:"10px" */
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.printimg {
  content: url(../Images/rafilogo.png);
  opacity: 0.2;
  position: fixed;
  top: 350px;
  left: 80px;
}

.bold {
  font-weight: bold;
}

.blackfont {
  color: black;
}

.button2 {
  font-family: Sitka;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border: none !important;
  color: white;
  text-align: center;
  font-size: 1vw;
  height: 39px;
  border-radius: 15px;
  outline: none !important;
  width: 12%;
}

.loginface {
  margin-bottom: 9px;
}

/* //suggest box css */
.suggestbox {
  padding-left: 0px;
  width: 75%;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #fff;
  outline: none !important;
  list-style: none;
  text-align: left;
  z-index: 20000;
  position: absolute;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.suggestboxtable {
  padding-left: 0px;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #fff;
  outline: none !important;
  list-style: none;
  text-align: left;
  z-index: 20000;
  position: absolute;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

/* suggest list css */
.listcss {
  border-bottom: none;
  border: 0.3px solid #707070;
  cursor: pointer;
  min-width: 100px;
  padding: 5px;
}

.listcsstable {
  border-bottom: none;
  border: 0.3px solid #707070;
  cursor: pointer;
  width: 100%;
  padding: 5px;
}

.listcss:hover {
  background-color: #337ab7;
  color: #fff;
}

.button1Clicked {
  height: 60px;
}

.button2Clicked {
  height: 60px;
}

.sizeInput {
  width: 30px;
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  height: 25px;
}

.loaderclass {
  display: inline-block;
}

.button1:focus {
  outline: none;
}

.button1:hover {
  height: 60px !important;
}

.button1Clicked {
  height: 60px !important;
}

.button2:focus {
  outline: none;
}

.button2:hover {
  height: 60px !important;
}

.button2Clicked {
  height: 60px !important;
}

.printer {
  cursor: pointer;
  width: 25px !important;
  height: 25px !important;
}

.trpointer {
  cursor: pointer;
}

.secondarybutton {
  background-color: white;
  border: 1px solid white;
  font-family: Sitka;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 15%;
  height: 39px;
  text-align: center;
  border: none;
  font-size: 0.9vw;
  border-radius: 15px;
  outline: none !important;
}

.secondarybuttonaccounts {
  background-color: white;
  border: 1px solid white;
  font-family: Sitka;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 9%;
  height: 39px;
  text-align: center;
  border: none;
  font-size: 0.9vw;
  border-radius: 15px;
  outline: none !important;
}

.secondarybuttonClickedaccounts {
  background-color: white;
  border: 1px solid white;
  font-family: Sitka;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 11%;
  height: 39px;
  text-align: center;
  border: none;
  font-size: 0.9vw;
  border-radius: 15px;
  outline: none !important;
}

.secondarybuttonClicked {
  background-color: white;
  border: 1px solid white;
  font-family: Sitka;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 15%;
  height: 39px;
  text-align: center;
  border: none;
  font-size: 0.9vw;
  border-radius: 15px;
  outline: none !important;
}

.productionTableButtons {
  background-color: white;
  border: 1px solid white;
  border-bottom: 3px solid #ef726f !important;
  font-family: Sitka;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: 12%;
  height: 39px;
  text-align: center;
  border: none;
  font-size: 0.9vw;
  border-radius: 15px;
  outline: none !important;
}

/*table css*/
.tableContainer {
  background-color: white;
  width: 100%;
  overflow: auto;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.tableContainer2 {
  background-color: white;
  width: 100% !important;
  overflow: auto;
  border: 1px solid lightgray;
  height: 84%;
  border-radius: 10px;
}

.h_full {
  height: 100% !important;
}

.pageheading {
  font-size: 0.9vw;
}

.partner_inputfields {
  width: 70%;
  border-radius: 5px;
  border: 1px solid #707070;
  background: #f2f2f299;
  height: 25px;
  font-size: 9pt;
  outline: none !important;
  font-family: sitka;
}

.tableContainer3 {
  margin-left: 2%;
  overflow: auto;
  background-color: white;
  width: 95%;
  margin-top: 3%;
}

.tableCash {
  line-height: 1.3pc;
}

.datatablecss {
  height: 75% !important;
  overflow: auto;
}

.datatablecssplot {
  height: 79vh !important;
  overflow: auto;
}

td {
  border-left: 1px solid rgb(199, 200, 201);
  border-right: 1px solid rgb(199, 200, 201);
}

tbody {
  font-size: 14px !important;
}

.datatablecssblock {
  height: 51vh !important;
  overflow: auto;
}

.dataTablecsSales {
  height: 85% !important;
  overflow: auto;
}

.datatablecssjournal {
  height: 75vh !important;
  overflow: auto;
}

.tableCash1 {
  height: 400px;
  overflow: auto;
}

.SettingContainer1 {
  background-color: white;
  width: 100%;
  /* height: 100% */
  border: 1px solid lightgray;
  border-radius: 10px;
  /* overflow: auto; */
}

.tableHeight {
  height: 90% !important;
  padding-bottom: 1%;
}

.add_icon {
  background-color: #35bf90;
  font-size: xx-large;
}

.drow {
  cursor: pointer;
}

.row1_style {
  background-color: #f2f6ff;
  min-height: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.table_row {
  background-color: #205370;
  padding-top: 10px;
  color: white;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
}

.table_row1 {
  background-color: #f2f6ff;
  padding-top: 10px;

  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
}

.input_border {
  border: 1px solid #cccccc;
  outline: none !important;
}

.yarnType {
  width: 155px;
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  height: 25px;
}

.tabletopbutton {
  background-color: #205370;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 2px 10px 0px 10px;
  border-radius: 5px;
}
.tabletopbutton1 {
  background-color: #205370;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 2px 2px 0px 2px;
  border-radius: 5px;
}

.tabletopbuttontr {
  background-color: #205370;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 2px 15px;
  border-radius: 5px;
}

.tabletopbuttontr:hover {
  background-color: #456be7;
}

.tabletopbuttonnew {
  background-color: #205370;
  outline: none !important;
  border: none !important;
  color: white;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 2px;
  height: 25px;
}

.tabletopbutton1 {
  background-color: #4f7eff;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  float: right;
}
.tabletopbutton12 {
  background-color: #4f7eff;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  float: left;
}
.wipbox {
  display: flex;
  justify-content: center;
  text-align: left;
  gap: 10px;
  width: 100%;
}
.tabletopbuttonJournal {
  background-color: #4f7eff;
  outline: none !important;
  border: none !important;
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
}

.tabletopbutton12 {
  background-color: #4f7eff;
  outline: none !important;
  border: none !important;
  color: white;
  /* padding: 5px 25px; */
}

/* accounts  css*/
.txt {
  font-size: 14px !important;
  color: #000000;
  font: normal normal normal 16px/21px Segoe UI;
}

.heading_chartA {
  display: inline;
}

.formInput {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 80%;
  height: 25px;
  font-size: small;
}

.formInput:focus {
  border: 3px solid #205370;
}

.formInputTable {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 50%;
  height: 25px;
  font-size: small;
}

.formInputfull {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 80%;
  height: 25px;
  font-size: small;
}

.formInputplot {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 94%;
  height: 25px;
  font-size: small;
}

.formInput1 {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 80%;
  height: 35px;
  font-size: small;
}

.date_input {
  border: none;
  outline: none !important;
  width: 30%;
}

.text_area_input {
  border: 1px solid grey;
  border-radius: 5px;
  outline: none !important;
  width: 80%;
  height: 50px;
  font-size: small;
}

th {
  font-weight: bold;
}

.th1 {
  font-size: 15px;
  /* padding-left: 10px; */
  color: white;
  opacity: 0.8;
  /* padding-right: 10px; */
}

.th2 {
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
  color: black;
  opacity: 0.8;
  padding-right: 10px;
}

.dashboardtoprow {
  background-color: white;
  border: 1px solid lightskyblue;
  text-align: center;
  height: 40vh;
}

.dashboardtoprow2 {
  background-color: white;
  border: 1px solid lightskyblue;
  text-align: center;
  height: 80vh;
}

.dashboardbttomrow {
  background-color: white;
  border: 1px solid lightskyblue;
  text-align: center;
  height: 48.5vh;
}

.loginbox {
  background-color: white;

  height: 260px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  margin-top: 1%;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 0px 5px 10px #00000029;
}

.loginbox1 {
  background-color: #205370;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  margin-top: 1%;
  padding: 15px 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.login_div {
  background-image: radial-gradient(#296d98, #012333);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectSelection {
  background-image: radial-gradient(#296d98, #012333);
  height: 100vh;
  display: flex;
}

.loginheading {
  text-align: center;
  margin-top: 2%;
  font-size: 13pt;
  font-family: TrajanProBold;
}

.loginimage {
  text-align: center;
  margin-top: 5%;
  font-size: 25pt;
}

/* // .activityindicator */
.activityindicator {
  align-items: center;
  align-content: center;
  display: none;
  height: 30px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.logininput {
  margin-top: 20px;
  padding-left: 20px !important;
  width: 100%;
  height: 35px;
  outline: none !important;
  border: 1px solid lightgrey;
  border-radius: 13px;
  padding: 5px 5px;
  font-family: Sitka;
  background: #f2f2f2;
  font-size: 10pt;
}

.logininput1 {
  float: left;
  margin-top: 20px;
  padding-left: 28px !important;
  width: 100%;
  height: 35px;
  outline: none !important;
  border: 1px solid lightgrey;
  border-radius: 13px;
  padding: 5px 5px;
  font-family: Sitka;
}

.loginicon {
  position: absolute;
  left: 26px;
  top: 25px;
}

.loginicon1 {
  position: relative;
  right: 114px;
  top: 50px;
}

.loginpass {
  position: relative;
  top: 40px;
}

.loginbutton {
  background-color: #27582e;
  outline: none !important;
  border: none;
  color: white;
  width: 115px;
  border-radius: 35px;
  font-family: TrajanProBold;
  font-size: 9pt;
}

.loginbutton:hover {
  background: rgb(115, 170, 118);
}

.password {
  margin-left: 170px;
  margin-top: 40px;
  font-size: 9pt;
  color: blue;
  cursor: pointer;
}

.loginlogo {
  width: 15em;
  padding-bottom: 10%;
}

.signin {
  font-size: 10pt;
}

.loginlist {
  display: flex !important;
  list-style: none !important;
  justify-content: center !important;
  box-sizing: border-box;
  font-size: 9pt;
  margin-top: 15%;
}

.loginlistitem {
  margin-right: 25px !important;
}

.w-40 {
  width: 48%;
}

.w-110 {
  width: 104%;
}

.modalscreen {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.filter_div {
  color: black;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  width: 100%;
  outline: none !important;
  padding: 1px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.filter_btn {
  margin-top: -8px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.custom_tooltip_date {
  position: absolute;
  background-color: #efefef;
  color: #fff;
  z-index: 89;
  height: auto;
  width: 250px;
  border-radius: 5px;
}

.custom_tooltip_date::before {
  top: -1%;
  left: 50%;
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 2px;
  background-color: #efefef;

  transform: rotate(45deg) translateY(-50%);
}

.input_style_date_modal {
  width: 50%;
  height: 28px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #707070;
}

.input_field {
  border: 1px solid #cccccc;
  outline: none !important;
  width: 100%;
  height: 28px;
  border-radius: 5px;
}

.date_modal_divs {
  cursor: pointer;
  margin-top: 5px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
}

.date_modal_divs:hover {
  background-color: var(--primary);
  color: #fff;
}

/* Modal Content/Box */
.modal-content-screen {
  background-color: #fefefe;
  margin: 226px auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  /* Could be more or less, depending on screen size */
}

.modal-content-screen3 {
  background-color: #fefefe;
  margin: 226px auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  /* Could be more or less, depending on screen size */
}

.modal-content-screen1 {
  background-color: #fefefe;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border: 1px solid #888;
  height: 100vh;
  overflow-y: auto;
  width: 70%;
  /* Could be more or less, depending on screen size */
}

.modal-content-screen2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  min-height: 230px;
  width: 350px;
}

.modal-content-screen-closing-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  height: 60vh;
  overflow-y: auto;
  width: 70%;
  /* Could be more or less, depending on screen size */
}

.modal-content-screenAlert {
  background-color: #fefefe;
  margin: auto;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #888;
  height: fit-content;
  overflow-y: auto;
  width: 50%;
  /* Could be more or less, depending on screen size */
}

.modal__content-screen-repurchase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  min-height: 230px;
  width: 350px;
}

@media screen and (min-width: 600px) {
  .modal__content-screen-repurchase {
    width: 500px;
  }
}

.modal__Input {
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-block: 5px 15px;
  outline: none;
}

.modal__Input:focus,
.modal__Input:focus-visible {
  border: 1px solid #555;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.width100 {
  width: 100% !important;
}

.jss2 {
  max-width: 100% !important;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.lightGray {
  background-color: #e7e8e9;
}

.intsallmentdiv {
  height: 50px;
  overflow: auto;
}

.removerow {
  cursor: pointer;
}

.contracttable {
  overflow: auto;
  height: 490px;
}

.contracttable1 {
  overflow: auto;
  height: 430px;
}

.contracttable2 {
  overflow: auto;
  height: 300px !important;
}

.tdcell {
  /* width: auto; */
  max-width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tdcell1 {
  width: auto;
  max-width: 150px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headingscss {
  display: inline-block;
}

.card-list {
  /* width: 85vw; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.card-container {
  display: flex;
  color: white;
  flex-direction: column;
  background-color: #205370;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.card-container:hover {
  transform: scale(1.05);
}

.h1css {
  font-family: "Bigelow Rules";
  font-size: 42px;
}

.height90 {
  height: 95% !important;
}

.modal-content-cat {
  background-color: #fefefe;
  margin: 8% auto;
  height: fit-content;
  border: 1px solid #888;
  padding-bottom: 15px;
  width: 50% !important;
}
.modal-content-cat3 {
  background-color: #fefefe;
  margin: 8% auto;
  height: fit-content;
  border: 1px solid #888;
  padding-bottom: 15px;
  width: 20% !important;
}
.modal-content-cat1 {
  background-color: #fefefe;
  margin: 12% auto;
  /* 15% from the top and centered */
  height: 35%;
  border: 1px solid #888;
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}

.canvasjs-chart-toolbar {
  display: none !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.table-div {
  max-height: 73vh !important;
  overflow: auto;

  /* padding: 0; */
}

.table-div table thead tr {
  position: sticky;
  top: 0;
  background-color: #205370 !important;
  z-index: 0.98;
}

.table-div-contract {
  max-height: 65vh !important;
  overflow: auto;

  /* padding: 0; */
}
.table-div-cash {
  max-height: 65vh !important;
  overflow: auto;

  /* padding: 0; */
}

.table-div-contract table thead tr {
  position: sticky;
  top: 0;
  background-color: #205370 !important;
  z-index: 0.98;
}

.table__div-plotwise {
  max-height: 42vh !important;
  overflow: auto;
}

.table__div-plotwise table thead tr {
  position: sticky;
  top: 0;
  background-color: #205370 !important;
  z-index: 0.98;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #205370;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #205370;
}

.ShowTableTotal {
  font-weight: bolder;
  font-size: 16px;
}
.table_small {
  height: 30vh;
  overflow: auto;
}

.dailyClosingGetInpt {
  width: 100%;
  padding: 5px;
  border: 0.3px solid #777;
}

.dailyClosingGetInpt:hover,
.dailyClosingGetInpt:focus {
  border: 0.3px solid black;
  outline: none;
}
